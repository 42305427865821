import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initComponents, initLayouts } from './components'
import { initValidations } from './validation'

import Notifications from '@kyvg/vue3-notification'

import { alertBox, fileUploadBox, imageBox, layoutSystem, modalBox } from '@ezio/ui'

import * as Sentry from '@sentry/vue'

import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { useEzioAPI, useEzioNodeAPI } from '@ezio/services'
import { useEzioWs } from '@ezio/services'
import { reset, useTokensStore } from './stores'
import { createPinia } from 'pinia'
import { useRoute } from 'vue-router'

console.warn(import.meta.env.APP_NAME, import.meta.env.APP_VERSION)

// const urls = {
//     api:
//         import.meta.env.MODE === 'development'
//             ? 'https://api-stg.ezio.app/'
//             : 'https://api.ezio.app/',
//     ws: import.meta.env.MODE === 'development' ? 'https://ws-stg.ezio.app/' : 'wss://ws.ezio.app/'
// }

const urls = {
    api: 'https://api.ezio.app/',
    // api: 'http://localhost:3333/',
    ws: 'wss://ws.ezio.app/'
}

const ezioApi = useEzioAPI()
    // useEzioNodeAPI()
    .init({
        url: urls.api,
        version: 'v1'
    })
ezioApi.onLoggingOut(() => {
    reset()
    const route = useRoute()
    if (route?.meta?.requireAuth) {
        router.push('/sign-in')
    }
})

const ezioWs = useEzioWs().init({
    url: urls.ws,
    version: ''
})

const app = createApp(App)
app.use(createPinia())
ezioApi.tokensStore = useTokensStore()
ezioWs.tokensStore = useTokensStore()

app.use(router)

app.use(alertBox)
app.use(imageBox)
app.use(fileUploadBox)
app.use(modalBox)

app.use(Notifications)

app.use(layoutSystem, {
    defaultLayout: 'Admin'
})

if (import.meta.env.MODE === 'production') {
    Sentry.init({
        app,
        dsn: 'https://275ea14c9b7c45d36236a855ba926dcc@o99640.ingest.sentry.io/4506780796059648',
        environment: 'production',
        release: import.meta.env.APP_VERSION,
        integrations: [
            Sentry.httpClientIntegration(),
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false
            })
        ],

        // This option is required for capturing headers and cookies.
        sendDefaultPii: true,
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/api\.ezio\.app/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

initComponents(app)
initLayouts(app)
initValidations()
dayjs.locale('fr')

app.mount('#app')

export default app
