<template>
    <div class="wrapper" ref="wrapper">
        <div class="current-agency" @click="show">
            <div>
                <avatar
                    v-if="layout === 'manager'"
                    :src="currentStore?.agency?.company?.logo?.url"
                    :text="
                        currentStore?.agency?.displayName || currentStore?.agency?.name || 'O'
                    " />
                <avatar v-else :text="currentStore?.organization?.name" />

                <div>
                    {{
                        layout === 'manager'
                            ? currentStore?.agency?.displayName || currentStore?.agency?.name
                            : currentStore?.organization?.name
                    }}
                </div>
            </div>
            <FeatherIcon name="chevron-down" :class="{ 'reverse-icon': isOpen }" />
        </div>

        <transition name="fade">
            <div v-if="isOpen" class="sub-menu">
                <div class="agencies">
                    <h4>Établissements</h4>

                    <SearchableList
                        ref="wrapper"
                        :items="agenciesStore.byName"
                        :searchKeys="['displayName']"
                        :showSearchBar="agenciesStore.byName.length >= 10">
                        <template #default="{ item }">
                            <button
                                class="agency"
                                :class="{
                                    current:
                                        currentStore.agencyId === item.id && layout === 'manager',
                                    'border-spin': agencyIdLoading === item.id
                                }"
                                @click="setAgency(item.id)">
                                {{ item.displayName || item.name }}
                            </button>
                        </template>
                    </SearchableList>

                    <div v-if="agenciesStore.byName.length < 1" class="agency">
                        Vous n'avez pas encore d'établissement
                    </div>

                    <!-- filter out agencies not active and deleted -->
                    <!-- <button
                        v-for="ag in agenciesStore.activesByName"
                        :key="ag.id"
                        class="agency"
                        :class="{
                            current: currentStore.agencyId === ag.id && layout === 'manager',
                            'border-spin': agencyIdLoading === ag.id
                        }"
                        @click.prevent="setAgency(ag.id)">
                        {{ ag.displayName || ag.name }}
                    </button> -->
                </div>
                <div class="orga">
                    <CRouterLink class="bare" to="/organization/agencies">
                        Gérer mon organisation
                    </CRouterLink>
                </div>
            </div>
        </transition>

        <Wallet v-if="layout === 'manager' && currentStore.agency" />
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { changeAgency, useAgenciesStore, useCurrentStore } from '@/stores'
import { useRouter } from 'vue-router'
import { defineAsyncComponent } from 'vue'

import Wallet from './Wallet.vue'
const SearchableList = defineAsyncComponent(() => import('@ezio/ui/content/SearchableList.vue'))

defineProps<{
    layout: 'organization' | 'manager'
}>()

const router = useRouter()

const agenciesStore = useAgenciesStore()
const currentStore = useCurrentStore()
const isOpen = ref(false)

const agencyIdLoading = ref<string>(null)
const wrapper = ref(null)
onClickOutside(wrapper, () => {
    isOpen.value = false
})

const show = async () => {
    isOpen.value = !isOpen.value
    await agenciesStore.fetchCollection()
}

const setAgency = async (id: string) => {
    agencyIdLoading.value = id
    router.push('/')
    await changeAgency(id)
    isOpen.value = false
    agencyIdLoading.value = null
}
</script>
<style lang="scss" scoped>
.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid var(--c-color-border-soft);
    border-radius: var(--c-border-radius);

    b {
        display: block;
    }
}

.current-agency {
    display: grid;
    grid-template-columns: 1fr 20px;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: 10px 0 10px 15px;
    & > div:first-child {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        width: max-content;
    }

    svg {
        transition: transform 0.25s ease-in-out;
        @media screen and (max-width: 799px) {
            display: none;
        }
    }

    .reverse-icon {
        transform: rotate(180deg);
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    max-width: 200px;

    width: 200%;
    // padding: 10px 15px;

    border: 1px solid var(--c-color-border-soft);
    border-radius: var(--c-border-radius);

    background: rgba(255, 255, 255);
    // backdrop-filter: blur(20px);

    .orga {
        border-bottom: 1px solid var(--c-color-border-soft);
        padding: 10px;
    }
    .agencies {
        overflow-y: scroll;
        max-height: 400px;
        h4 {
            padding: 6px 10px;
            margin: 10px 0 0;
        }
        .agency {
            display: block;
            width: 100%;
            text-align: left;
            padding: 10px 12px;
            background: none;
            border: 0;
            border-top: 1px solid var(--c-color-border-soft);
            cursor: pointer;
            &.current {
                display: flex;
                justify-content: space-between;
                font-weight: 800;
                color: var(--c-color-primary);
                &:after {
                    content: '✓';
                }
            }
        }
    }

    a,
    button {
        padding: 4px;
        color: var(--color-text-hard);
    }
}

.border-spin {
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: #fff;
    color: var(--c-color-primary);
    &::before {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        width: 500px;
        height: 500px;
        animation: rotate 3s infinite linear !important;
        background: var(--c-color-primary);
        transform-origin: 0 0;
        z-index: -1;
        @keyframes rotate {
            100% {
                transform: rotate(-360deg);
            }
        }
    }
    &::after {
        content: '';
        position: absolute;
        display: grid;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        place-items: center;
        font-size: 10px;
        background: #fff;
        z-index: -1;
    }
}
</style>
