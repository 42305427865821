import { ref } from 'vue'
import * as pdfjsLib from 'pdfjs-dist'
import 'pdfjs-dist/build/pdf.worker.mjs'
import { useBase64 } from '@ezio/utils'
import LogoPdf from '@ezio/assets/images/logo-pdf.png'

let instance: any = null

const createInstance = () => {
    const accept = 'image/png,image/gif,image/jpeg,application/pdf'
    const current = ref(0)
    const visible = ref(false)
    const preview = ref<string>(null)
    const maxSize = 3000

    let onSelectCallback: (file: string) => unknown = null

    const show = async (callback: (file: string) => unknown) => {
        visible.value = true
        preview.value = null
        onSelectCallback = callback
    }

    const dismiss = () => {
        visible.value = false
        onSelectCallback(null)
    }

    const onSelectFile = async (file: any) => {
        const fileType = file.type

        let b64File: string
        // if (fileType.indexOf('image') > -1) {
        //     const { convertImageToB64 } = useBase64()
        //     b64File = await convertImageToB64(file, maxSize)
        //     preview.value = b64File
        // } else {
        //     const { convertFileToB64 } = useBase64()
        //     b64File = await convertFileToB64(file)
        //     preview.value = LogoPdf
        // }

        // await onSelectCallback([b64File])
        if (fileType.indexOf('image') > -1) {
            const { convertImageToB64 } = useBase64()
            b64File = await convertImageToB64(file, maxSize)
            preview.value = b64File
            await onSelectCallback(b64File)
        } else if (fileType.indexOf('pdf') > -1) {
            const pdfData = new Uint8Array(await file.arrayBuffer())
            const pdfDoc = await pdfjsLib.getDocument({ data: pdfData }).promise

            const pageNumbers = Array.from({ length: pdfDoc.numPages }, (_, i) => i + 1)

            for (const pageNum of pageNumbers) {
                const page = await pdfDoc.getPage(pageNum)
                const viewport = page.getViewport({ scale: 1 })

                const canvas = document.createElement('canvas')
                const context = canvas.getContext('2d')!
                canvas.width = viewport.width
                canvas.height = viewport.height

                // Rendu de la page dans le canvas
                await page.render({ canvasContext: context, viewport }).promise

                // Conversion du canvas en base64
                b64File = canvas.toDataURL('image/png')
                // b64Files.push(imageBase64)

                await onSelectCallback(b64File)
            }

            preview.value = b64File // Prévisualiser la première page par exemple
            // Callback avec toutes les pages en base64
        } else {
            const { convertFileToB64 } = useBase64()
            const b64File = await convertFileToB64(file)
            preview.value = LogoPdf
            await onSelectCallback(b64File)
        }
        visible.value = false
    }

    return {
        accept,
        preview,
        current,
        visible,
        onSelectFile,
        show,
        dismiss
    }
}

export const useFileUploadBox = () => {
    if (instance) {
        return {
            fileUploadBox: instance
        }
    }
    instance = createInstance()
    return {
        fileUploadBox: instance
    }
}
